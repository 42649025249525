import React from 'react'
import logo from '../../assets/images/zaven_logo_rgb_color_neg.png'
import { graphql, StaticQuery } from 'gatsby';
import Link from '../common/Link';
import {
  Collapse,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavbarToggler,
  NavItem,
} from 'reactstrap'

class NavBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isAboutMenuOpen: false,
      isServicesMenuOpen: false,
      isNavbarExpanded: false,
    }
  }

  componentDidMount() {
    const { fixedPosition, transparentBackground } = this.props
    // Toggle navbar class if it's transparent and view is scrolled down
    if (fixedPosition && transparentBackground) {
      this.addOnScrollListener()
    }
  }

  componentWillUnmount() {
    this.removeOnScrollListener();
  }

  toggleServicesDropdown = () => {
    this.setState(prevState => ({
      isServicesMenuOpen: !prevState.isServicesMenuOpen,
    }))
  }

  toggleAboutDropdown = () => {
    this.setState(prevState => ({
      isAboutMenuOpen: !prevState.isAboutMenuOpen,
    }))
  }

  toggleNavbarCollapse = () => {
    this.setState(prevState => ({
      isNavbarExpanded: !prevState.isNavbarExpanded,
    }))

    this.state.isNavbarExpanded && this.props.transparentBackground
      ? this.nav.classList.add('navbar-transparent', 'bg-transparent')
      : this.nav.classList.remove('navbar-transparent', 'bg-transparent')
  }

  addOnScrollListener = () => {
    window.addEventListener('scroll', this.onScroll)
  }

  onScroll = () => {
    window.pageYOffset > 0
      ? this.nav.classList.add('navbar-scrolled')
      : this.nav.classList.remove('navbar-scrolled')

    window.pageYOffset > 0 && this.setState({ isNavbarExpanded: false });
  }

  removeOnScrollListener = () => {
    window.removeEventListener('scroll', this.onScroll)
  }

  removeOnClickListener = () => {
    window.removeEventListener('click', this.onClick)
  }

  render() {
    const {
      fixedPosition,
      transparentBackground,
      lightTheme,
      location,
    } = this.props

    const navbarClass = `
      navbar flex-wrap flex-column justify-content-between navbar-expand-lg
      ${transparentBackground ? 'navbar-transparent bg-transparent' : ''}
      ${fixedPosition ? 'fixed-top' : 'navbar-static'}
      ${lightTheme ? 'navbar-light bg-lightest' : 'navbar-dark bg-dark'}
    `

    const dropdownMenuClass = `
      ${lightTheme ? 'bg-light-active' : 'bg-dark'}
    `
    // prevents 'cannot read 'pathname' of undefined' error
    const isActive = (location, type) => {
      return location && location.pathname && location.pathname.includes(type)
        ? 'active'
        : ''
    }

    return (
      <StaticQuery
        query={graphql`
          query NavBarQuery {
            dataJson {
              contact {
                phone
                phoneClean
                email
              }
            }
            logoLight: imageSharp(fluid: { originalName: { regex: "/zaven_logo_rgb_color_pos/" } }) {
              resolutions(quality: 100) {
                ...GatsbyImageSharpResolutions
              }
            }
          }
        `}
        render={data => {
          const { email, phone, phoneClean } = data.dataJson.contact
          return (
            <div id="navbar-main">
              <nav
                ref={r => (this.nav = r)}
                className={navbarClass}
                style={this.props.style}
              >
                <Container className="justify-content-center justify-content-sm-end">
                </Container>
                <Container>
                  <div className="navbar-brand w-auto">
                    <Link className="zaven-brand text-white mr-1" to="/">
                      <img
                        src={lightTheme ? data.logoLight.resolutions.src : logo}
                        className="zaven-logo "
                        alt="Software House Wrocław (Poland) - Zaven"
                      />
                    </Link>
                    <em className="d-md-inline d-none">&nbsp;|&nbsp;</em>
                    <h1 className="tagline d-md-inline d-none ml-1">
                      Software Development Poland
                    </h1>
                  </div>
                    <NavbarToggler onClick={this.toggleNavbarCollapse} />
                  <Collapse
                    isOpen={this.state.isNavbarExpanded}
                    className="justify-content-md-end"
                    navbar
                  >
                    <Nav className="navbar-nav align-items-center mb-lg-0 pr-lg-0 mb-4 mt-3 mt-lg-0 text-right pr-2">
                      <Dropdown
                        isOpen={this.state.isServicesMenuOpen}
                        toggle={this.toggleServicesDropdown}
                        nav
                        inNavbar
                        className={isActive(location, 'services')}
                      >
                        <DropdownToggle
                          caret
                          tag="a"
                          className="nav-link text-uppercase"
                          style={{ cursor: 'pointer' }}
                        >
                          Tjänster
                        </DropdownToggle>
                        <DropdownMenu
                          className={`${dropdownMenuClass} text-right text-lg-left`}
                        >
                          <DropdownItem
                            tag={Link}
                            to="/services/mobile-app-development-poland/"
                            className="service-mobile"
                            activeClassName="active"
                          >
                           Mobil utveckling
                            <br />
                            <em className="text-sm text-muted">
                             Android- och iOS-appar
                            </em>
                          </DropdownItem>
                          <DropdownItem
                            tag={Link}
                            to="/services/web-app-development-poland/"
                            className="service-web"
                            activeClassName="active"
                          >
                              Webbutveckling
                            <br />
                            <em className="text-sm text-muted">
                              Webbappar och -tjänster
                            </em>
                          </DropdownItem>
                          <DropdownItem
                            tag={Link}
                            to="/services/poland-software-outsourcing/"
                            className="service-outsourcing"
                            activeClassName="active"
                          >
                            IT Outsourcing
                            <br />
                            <em className="text-sm text-muted">
                              Skala upp ditt utvecklingsteam
                            </em>
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                      <NavItem className={isActive(location, 'projects')}>
                        <Link to="/projects/" className="nav-link text-uppercase">
                          projekt
                        </Link>
                      </NavItem>
                      <NavItem className={isActive(location, 'career')}>
                        <Link to="/career/" className="nav-link text-uppercase">
                          karriär
                        </Link>
                      </NavItem>
                      <NavItem className={isActive(location, 'about')}>
                        <Link to="/about/" className="nav-link text-uppercase">
                          om oss
                        </Link>
                      </NavItem>
                      <NavItem>
                        <a
                          href="/blog/"
                          className="nav-link text-uppercase"
                        >
                          blogg
                        </a>
                      </NavItem>
                      <NavItem className={isActive(location, 'contact')}>
                        <Link to="/contact/" className="nav-link text-uppercase">
                          Kontakt
                        </Link>
                      </NavItem>
                      <form className="form-inline ml-md-2 mt-3 mt-lg-0">
                        <Link
                          to="/career/"
                          className={`btn ${
                            lightTheme ? 'btn-warning' : 'btn-outline-warning'
                          }`}
                        >
                          <strong>We're hiring!</strong>
                        </Link>
                      </form>
                    </Nav>
                  </Collapse>
                </Container>
              </nav>
            </div>
          )
        }}
      />
    )
  }
}

export default NavBar
